import React from 'react';
import Contact from '../components/Contact';

const Contacto = () => {
  return (
    <section id="contacto" className=" contacto container mb-5 ">
      <Contact/>
    </section>
  )
}

export default Contacto;